import React, { useEffect, useState } from "react";
import TopBar from "../TopBar";
import Footer from "../Footer";
import Chat from "../commonComponents/Chat";
import UploadSideBar from "./UploadSideBar";
import Tabs from "../commonComponents/Tabs";
import axios from "axios";
import { useParams } from "react-router-dom";
import { PanelGroup, Panel, PanelResizeHandle } from "react-resizable-panels";
import useSpeechToText from "react-hook-speech-to-text";
import toast from "react-hot-toast";
import AudioTabs from "../commonComponents/AudioTabs";

function LecturePage({
  setHeroLoader,
  loggedInId,
  setLoggedInId,
  isPaymentDone,
  setIsPaymentDone,
  paymentPopup,
  setPaymentPopup,
  showSub,
  setShowSub,
  showDisplayFeatureModel,
  setDisplayFeatureModel,
  userQuestions,
  setUserQuestions,
  emailData,
  setEmailData,
  pricingPlansPopup,
  setPricingPlansPopup,
  flag,
  setFlag,
  logintype,
  setLoginType,
  show,
  setShow,
  handleClose,
  handleShowLogin,
}) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [messages, setMessages] = useState([]);
  const { id } = useParams();
  const [inputUrlVal, setInputUrlValue] = useState("");
  const [summary, setSummary] = useState("");
  const [notes, setNotes] = useState("");
  const [flashcards, setFlashcards] = useState("");
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [loadingFlashCards, setLoadingFlashCards] = useState(false);
  const [isUploaded, setIsUploaded] = useState(
    "Please enter Youtube URL to continue"
  );
  const [showTranscriptText, setShowTranscriptText] = useState([]);
  const [activeId, setActiveId] = useState("");
  const [formattedResults, setFormattedResults] = useState([]);
  const [stopClick, setStopClick] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [progressComplete, setProgessComplete] = useState(false);
  const [summaryPercentage, setSummaryPercentage] = useState(0);
  const [notesPercentage, setNotesPercentage] = useState(0);
  const [flashcardPercentage, setFlashcardPercentage] = useState(0);
  const {
    error,
    interimResult,
    isRecording,
    results,
    setResults,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    crossBrowser: true,
    googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    useLegacyResults: false,
    interimResults: false,
  });
  useEffect(() => {
    if (percentage === 100) {
      setProgessComplete(true);
    } else {
      setProgessComplete(false);
    }
  }, [percentage]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          setLoadingFlashCards(true);
          setLoadingNotes(true);
          setLoadingSummary(true);
          setIsUploaded("");

          await uploadId();
          await Promise.all([
            fetchSummary(),
            fetchNotes(),
            fetchFlashCards(),
            fetchChatHistoryApi(),
          ]);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setLoadingFlashCards(false);
        setLoadingNotes(false);
        setLoadingSummary(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const formatResults = (results) => {
      return results.map((result, index) => {
        let transcript = result.transcript.trim();
        const nextResult = results[index + 1] || null;

        const isQuestion =
          transcript.toLowerCase().startsWith("who") ||
          transcript.toLowerCase().startsWith("what") ||
          transcript.toLowerCase().startsWith("where") ||
          transcript.toLowerCase().startsWith("when") ||
          transcript.toLowerCase().startsWith("why") ||
          transcript.toLowerCase().startsWith("how");

        // Add punctuation
        if (isQuestion) {
          transcript += "?";
        } else if (nextResult) {
          const timeGap = nextResult.timestamp - result.timestamp;
          if (timeGap < 2000) {
            transcript += ".";
          } else {
            transcript += ".";
          }
        } else {
          transcript += ".";
        }

        return {
          ...result,
          transcript,
        };
      });
    };

    setFormattedResults(formatResults(results));
  }, [results]);

  useEffect(() => {
    // Ensure startSpeechToText is called only when results exist and isRecording is false
    if (results.length > 0 && !isRecording && !stopClick) {
      startSpeechToText();
    }
  }, [results, isRecording, stopClick, startSpeechToText]);

  useEffect(() => {
    if (activeId != "") {
      fetchTranscript();
    }
  }, [activeId]);

  const sendMessageApi = async (message, updateId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/youtubesummary-chat`,
        {
          method: "POST",
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            question: message,
            video_id: updateId,
          }),
        }
      );
      return response;
    } catch (error) {
      console.error("Error sending message:", error);
      throw error;
    }
  };

  const sendAudioMessageApi = async (message, activeId) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/web-user-book-chat`,
      {
        method: "POST",
        headers: {
          "x-access-token": JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: message,
          file_id: activeId,
          file_type: "record",
        }),
      }
    );
    return response;
  };

  const uploadId = async () => {
    try {
      if (id) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/youtube-id`,
          { videoId: id, link: inputUrlVal },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        );
        return response;
      }
    } catch (error) {
      console.error("Error uploading ID:", error);
      throw error;
    }
  };

  const increasePercentageForSummary = () => {
    let timer = 1;
    setSummaryPercentage((prevPercentage) => {
      let newPercentage = prevPercentage + timer;
      return newPercentage > 100 ? 100 : newPercentage;
    });
  };
  const increasePercentageForNotes = () => {
    let timer = 1;
    setNotesPercentage((prevPercentage) => {
      let newPercentage = prevPercentage + timer;
      return newPercentage > 100 ? 100 : newPercentage;
    });
  };
  const increasePercentageForFlashCards = () => {
    let timer = 1;
    setFlashcardPercentage((prevPercentage) => {
      let newPercentage = prevPercentage + timer;
      return newPercentage > 100 ? 100 : newPercentage;
    });
  };

  const fetchSummary = async (paramType = false) => {
    try {
      if (id) {
        setSummaryPercentage(0);
        var interval = setInterval(increasePercentageForSummary, 100);
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/youtube-summary-chat`,
          { video_id: id, regenerate: paramType },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        );
        setSummaryPercentage(100);
        clearInterval(interval);
        setSummary(response.data.data.answer);
        setPercentage(0);
        setLoadingSummary(false);
      }
    } catch (error) {
      console.error("Failed to fetch the summary:", error);
      throw error;
    }
  };

  const fetchNotes = async (paramType = false) => {
    try {
      if (id) {
        setLoadingNotes(true);
        setNotesPercentage(0);
        var interval = setInterval(increasePercentageForNotes, 180);
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/youtube-notes-chat`,
          { video_id: id, regenerate: paramType },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        );
        setNotesPercentage(100);
        clearInterval(interval);
        setNotes(response.data.data.answer);
        setPercentage(0);
        setLoadingNotes(false);
      }
    } catch (error) {
      console.error("Failed to fetch notes:", error);
      throw error;
    }
  };

  const fetchFlashCards = async (paramType = false) => {
    try {
      if (id) {
        setLoadingFlashCards(true);
        setFlashcardPercentage(0);
        var interval = setInterval(increasePercentageForFlashCards, 160);
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/youtube-flashcard-chat`,
          { video_id: id, regenerate: paramType },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
            onUploadProgress: function (event) {
              setPercentage(Math.round((100 * event.loaded) / event.total));
            },
          }
        );
        setFlashcardPercentage(100);
        clearInterval(interval);
        setFlashcards(response.data.data.answer);
        setPercentage(0);
        setLoadingFlashCards(false);
      }
    } catch (error) {
      console.error("Failed to fetch flashcards:", error);
      throw error;
    }
  };

  const fetchChatHistoryApi = async () => {
    try {
      if (id) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/user-lecture-old-chat`,
          { videoId: id },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        );
        return response;
      }
    } catch (error) {
      console.error("Failed to fetch chat history:", error);
      throw error;
    }
  };

  const fetchAudioChatHistoryApi = async () => {
    try {
      if (id) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/user-lecture-old-chat`,
          { videoId: id, file_id: activeId },
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        );
        return response;
      }
    } catch (error) {
      console.error("Failed to fetch chat history:", error);
      throw error;
    }
  };

  const fetchTranscript = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/lecture-transcript`,
        {
          file_id: activeId,
        },
        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (response.data.status) {
        let newArr = [{ transcript: response.data.file_content }];
        setShowTranscriptText(newArr);
        setResults([]);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const reGenerateSummFn = (type) => {
    if (type == "summary") {
      fetchSummary(true);
      setLoadingSummary(true);
    } else if (type == "notes") {
      setLoadingNotes(true);
      fetchNotes(true);
    } else if (type == "flashCards") {
      setLoadingFlashCards(true);
      fetchFlashCards(true);
    }
  };

  return (
    <>
      <TopBar
        handleShowLogin={handleShowLogin}
        handleClose={handleClose}
        show={show}
        setShow={setShow}
        logintype={logintype}
        setLoginType={setLoginType}
        pricingPlansPopup={pricingPlansPopup}
        setPricingPlansPopup={setPricingPlansPopup}
        emailData={emailData}
        setEmailData={setEmailData}
        userQuestions={userQuestions}
        setUserQuestions={setUserQuestions}
        showDisplayFeatureModel={showDisplayFeatureModel}
        setDisplayFeatureModel={setDisplayFeatureModel}
        setHeroLoader={setHeroLoader}
        setFlag={setFlag}
        flag={flag}
        loggedInId={loggedInId}
        setLoggedInId={setLoggedInId}
        isPaymentDone={isPaymentDone}
        setIsPaymentDone={setIsPaymentDone}
        setPaymentPopup={setPaymentPopup}
        paymentPopup={paymentPopup}
        showSub={showSub}
        setShowSub={setShowSub}
      />
      <div className="flex container-fluid md:h-screen md:max-h-screen main-div-container overflow-y-auto">
        <div className="container-fluid py-14 md:py-8 px-21 lg:px-12 flex-grow flex flex-col">
          <div className="pb-9">
            <h1 className="text-4xl pb-3">Lecture</h1>
            <h3 className="text-base text-gray-500">
              Upload any lecture material and instantly receive tailored
              summaries, interactive flashcards, practice questions, and
              AI-powered assistance.
            </h3>
          </div>
          <div className="flex flex-grow flex-col md:flex-row">
            <PanelGroup
              direction={isMobile ? "vertical" : "horizontal"}
              className="max-md:h-screen panel-group">
              <Panel className="w-full p-4 border border-gray-500 rounded-l-2xl md:w-1/4 div-border-responsive max-md:min-h-screen md:h-[700px] overflow-y-auto">
                <UploadSideBar
                  setMessages={setMessages}
                  fetchChatHistoryApi={fetchChatHistoryApi}
                  inputUrlVal={inputUrlVal}
                  setInputUrlValue={setInputUrlValue}
                  uploadId={uploadId}
                  setIsUploaded={setIsUploaded}
                  startSpeechToText={startSpeechToText}
                  setResults={setResults}
                  setShowTranscriptText={setShowTranscriptText}
                  setActiveId={setActiveId}
                  stopSpeechToText={stopSpeechToText}
                  results={formattedResults}
                  error={error}
                  setStopClick={setStopClick}
                  fetchFlashCards={fetchFlashCards}
                  fetchNotes={fetchNotes}
                  fetchSummary={fetchSummary}
                />
              </Panel>
              <PanelResizeHandle />
              <Panel>
                <div className="w-full p-4 border border-gray-500 max-h-full md:h-[700px] overflow-y-auto div-border-responsive div-margin-height-responsive max-md:p-0 notes-panel">
                  {id === "recording" ? (
                    <AudioTabs
                      summary={summary}
                      notes={
                        formattedResults.length > 0
                          ? formattedResults
                          : showTranscriptText
                      }
                      activeId={activeId}
                      interimResult={interimResult}
                      stopClick={stopClick}
                    />
                  ) : (
                    <Tabs
                      summary={summary}
                      notes={notes}
                      flashcards={flashcards}
                      loadingFlashCards={loadingFlashCards}
                      loadingNotes={loadingNotes}
                      loadingSummary={loadingSummary}
                      isUploaded={isUploaded}
                      reGenerateSummFn={reGenerateSummFn}
                      summaryPercentage={summaryPercentage}
                      notesPercentage={notesPercentage}
                      flashcardPercentage={flashcardPercentage}
                    />
                  )}
                </div>
              </Panel>
              <PanelResizeHandle />
              <Panel>
                <div className="w-full p-4 border border-gray-500 rounded-r-2xl md:h-[700px] overflow-y-auto div-border-responsive div-margin-height-responsive">
                  {id === "recording" ? (
                    <Chat
                      fetchChatHistoryApi={fetchAudioChatHistoryApi}
                      sendMessageApi={sendAudioMessageApi}
                      videoId={id}
                      messages={messages}
                      setMessages={setMessages}
                      activeId={activeId}
                    />
                  ) : (
                    <Chat
                      fetchChatHistoryApi={fetchChatHistoryApi}
                      sendMessageApi={sendMessageApi}
                      videoId={id}
                      messages={messages}
                      setMessages={setMessages}
                    />
                  )}
                </div>
              </Panel>
            </PanelGroup>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default LecturePage;
